import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Contactus from './Contactus'

const ContactPage = () => {
  return (
   <>
   <Header />


    <Contactus />
   <Footer />
   </>
  )
}

export default ContactPage
